import Cookies from 'js-cookie'

const token = 'ToKen'

export const setToken = (data: string) => Cookies.set(token, data)
export const getToken = () => Cookies.get(token)
export const removeToken = () => Cookies.remove(token)


export const setAppType = (data: string) => Cookies.set('AppType', data)
export const getAppType = () => Cookies.get('AppType')
export const removeAppType = () => Cookies.remove('AppType')
