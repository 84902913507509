import axios from 'axios'
import { Toast } from 'vant'
import { getToken } from '@/utils/cookies'
import router from '@/router'

let setUrl = sessionStorage.getItem('URL')
let baseURL = setUrl || 'https://api.qiantaohotel.com:10009/QianTaoWebService/'

/**
 * @url 路径
 * @data 传参
 * @method 请求方式，默认post(可选)
 */
export default (url: string, data = {}, method = 'post') => {
  // 添加请求拦截器
  axios.interceptors.request.use(
    (config) => {
      // 在发送请求之前做些什么
      if (getToken()) {
        config.headers.token = getToken()
      }

      return config
    },
    (error) => {
      // 对请求错误做些什么
      return Promise.reject(error)
    }
  )

  // 添加响应拦截器
  axios.interceptors.response.use(
    (response) => {
      // 2xx 范围内的状态码都会触发该函数。
      // 对响应数据做点什么
      if (response.data.Status === 200) {
        return response
      } else if (response.data.Status === 201) {
        Toast({ message: '未登录', forbidClick: true })
        if (sessionStorage.getItem('WeChatLoginUrl')) {
          window.location.href = sessionStorage.getItem('WeChatLoginUrl')
        }
        throw new Error(response.data.ErrorMessage)
      } else {
        Toast({ message: response.data.ErrorMessage, forbidClick: true })

        throw new Error(response.data.ErrorMessage)
      }
      // console.log('响应', response)
    },
    (error) => {
      // 超出 2xx 范围的状态码都会触发该函数。
      // 对响应错误做点什么
      return Promise.reject(error)
    }
  )

  if (sessionStorage.getItem('baseURL')) baseURL = sessionStorage.getItem('baseURL') || ''

  return axios({
    baseURL,
    url,
    method,
    data
  })
}
